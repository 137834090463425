<template>
    <div class="h-100"  style="min-height: 82vh;">
      <div v-if="isLoading">
        <center style="margin-top: 25%">
          <span class="spinner-border text-dark spinner-border-lg ml-1"></span>
        </center>
      </div>
      <!-- <div v-else class="h-100"  style="min-height: 82vh;"> -->
  
        <div v-if="isError">
          <center class="mt-3">
            <h3>Something went wrong...</h3>
            <p>{{ isError }}</p>
          </center>
        </div>
        <div v-else-if="res && !res.interviewers.includes(userData.hashid)">
          <center>
            <h4>You don't have access buddy</h4>
            <button class="btn">Request Access</button>
          </center>
        </div>
  
        <div class="h-100"  v-else-if="res" style="min-height: 82vh;">
    <template>

      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Submit Feedback</h5>
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form autocomplete="off" id="feedback-form" class="p-2" @reset.prevent="resetForm" v-if="res != null">
          <b-form-group label="Your Overall Recommendation">
            <b-form-radio-group
              v-model="res.reviews[0].overall_rating"
              buttons
              button-variant="outline-primary"
              class="mx-n1"
              size="sm"
              name="overall_rating"
            >
              <template v-for="option in options">
                <b-form-radio
                  :value="option.value"
                  :key="option.text"
                  :class="`rounded-pill ml-1 ` + `${option.color}`"
                >
                  <div class="d-flex text-truncate align-items-center">
                    <feather-icon
                      class="cursor-pointer"
                      :icon="option.icon"
                      size="16"
                    />
                    {{ option.text }}
                  </div>
                </b-form-radio>
              </template>
            </b-form-radio-group>
          </b-form-group>
          <validation-provider
            #default="validationContext"
            name="feedback-privacy"
          >
            <b-form-group
              label="Feedback Privacy   (If enabled, Only Admins able to view your feedback)"
              label-for="feedback-privacy"
            >
              <b-form-checkbox
                :checked="res.reviews[0].feedback_privacy"
                class="custom-control-primary"
                name="feedback_privacy"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="EyeOffIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="EyeIcon" />
                </span>
              </b-form-checkbox>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Review"
            rules="required"
          >
          <!-- {{ res.status }} -->
            <b-form-group label="Overall Comments" label-for="review">
              <b-form-textarea
                id="review"
                v-model="res.reviews[0].review"
                name="review"
                :state="getValidationState(validationContext)"
                trim
                rows="3"
                max-rows="6"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group v-for="(item, index) in customFields" :key="item.id">
            <validation-provider
              #default="{ errors }"
              :name="item.name"
              rules=""
            >
              <b-form-group
                v-if="item.type == 'number'"
                :label="item.label"
                label-for="input-1"
              >
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>

              <b-form-group
                v-if="item.type == 'text'"
                :label="item.label"
                label-for="input-1"
              >
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>

              <b-form-group
                v-if="item.type == 'rating'"
                :label="item.label"
                label-for="input-1"
              >
                <div class="d-flex align-items-center">
                  <star-rating
                    v-model="get_customfields_data['field_' + item.id]"
                    :show-rating="false"
                    :star-size="30"
                  ></star-rating>
                  <span
                    v-if="get_customfields_data['field_' + item.id]"
                    :class="`rating-btn btn ${
                      ratingdescription[
                        get_customfields_data['field_' + item.id]
                      ].class
                    }`"
                  >
                    {{
                      ratingdescription[
                        get_customfields_data["field_" + item.id]
                      ].text
                    
                    }}
                    </span
                  >
                  <input
                    type="hidden"
                    v-model="get_customfields_data['field_' + item.id]"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                  />
                </div>
              </b-form-group>

              <b-form-group
                v-if="item.type == 'password'"
                :label="item.label"
                label-for="input-1"
              >
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="password"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>

              <b-form-group
                v-if="item.type == 'textarea'"
                :label="item.label"
                label-for="input-1"
              >
                <b-form-textarea
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="get_customfields_data['field_' + item.id]"
                  trim
                  :placeholder="item.label"
                  rows="3"
                  max-rows="6"
                />
              </b-form-group>
              <b-form-group
                v-if="item.type == 'date'"
                :label="item.label"
                label-for="input-1"
              >
                <flat-pickr
                  :config="{ dateFormat: 'Y-m-d' }"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="get_customfields_data['field_' + item.id]"
                  class="form-control"
                  placeholder="Select Date.."
                />
              </b-form-group>

              <b-form-group
                v-if="item.type == 'textarea_rating' && false"
                label=""
                label-for="input-1"
              >
                <div class="d-flex align-items-center">
                  <label for="rating-inline">{{ item.label }}</label>
                  <b-form-rating
                    id="rating-inline"
                    icon-clear="slash-circle"
                    :model="`${item.name}_rating`"
                    :name="`custom_fields_data[${item.name}_rating-${item.id}]`"
                    show-clear
                    class="mb-1 ml-auto mr-0"
                    inline
                    show-value
                  ></b-form-rating>
                </div>
                <b-form-textarea
                  id="review"
                  :model="`${item.name}_note`"
                  :name="`custom_fields_data[${item.name}_note-${item.id}]`"
                  trim
                  placeholder="Note"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="validationForm"
              :disabled="is_loading"
            >
              {{ res.reviews[0].interviewer_review ? 'Update Feedback' : 'Add Feedback' }}
              <span
                class="spinner-border text-white spinner-border-sm ml-1"
                v-if="is_loading"
              ></span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
            >
              Back
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
        </div>
  
        <!-- <div v-else>
          {{ res.status }}
        </div> -->
      </div>
    <!-- </div> -->
  </template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormCheckbox,
  BFormTextarea,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import StarRating from "vue-star-rating";
import store from "@/store";
import interviewStoreModule from "../../interviewStoreModule";
import axios from "@axios";
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
  
export default {
    components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
    data() {
      return {
        res: null,
        isError: null,
        isLoading: true,
        required,
        userData:{},
      alphaNum,
      get_customfields_data: [],
      email,
      dataLocal: {
        status: false,
        review: "",
      },
      is_loading: false,
      options: [
        {
          text: "Strongly Hire",
          value: "Strongly Hire",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
        },
        {
          text: "Hire",
          value: "Hire",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
        },
        {
          text: "Hold",
          value: "Hold",
          color: "btn-outline-primary",
          icon: "PauseIcon",
        },
        {
          text: "No Hire",
          value: "No Hire",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
        },
        {
          text: "Strong No Hire",
          value: "Strong No Hire",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
        },
      ],
      criteria: [
        { label: "Technical Skills", value: "Technical Skills" },
        { label: "Cultural Fit", value: "Cultural Fit" },
        { label: "Reading & Writing", value: "Reading & Writing" },
        {
          label: "Business Understanding / Entrepreneurial Skills",
          value: "Business Understanding / Entrepreneurial Skills",
        },
      ],
      feedback: {
        overall_rating: "",
        review: "",
        highlight: "",
        technical_skills_rating: "",
        technical_skills_note: "",
        cultural_fit_rating: "",
        cultural_fit_note: "",
        reading_writing_rating: "",
        reading_writing_note: "",
        business_understanding_rating: "",
        business_understanding_note: "",
        feedback_privacy: "",
      },
      customFields: [],
      star: 2,
      maxstars: 5,
      hasresults: true,
      hasdescription: true,
      disabled: false,
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      starsize: "lg", //[xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
      };
    },
    setup() {
    const blankUserData = {
      status: false,
      review: "",
    };

    // const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      //  userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);


      const USER_APP_STORE_MODULE_NAME = "app-interviews";

// Register module
if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
  store.registerModule(USER_APP_STORE_MODULE_NAME, interviewStoreModule);

// UnRegister on leave
onUnmounted(() => {
  if (store.hasModule(USER_APP_STORE_MODULE_NAME))
    store.unregisterModule(USER_APP_STORE_MODULE_NAME);
});

    return {
      // userData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
    created() {
      this.checkUser();
      this.getCustomFields();
      this.get_customfields_data = [];
    },
  mounted() {
    axios
        .get(`/interviews/${this.$route.params.id}`)
        .then((result) => {
          this.res = result.data.data;
          this.get_customfields_data = result.data.data.reviews[0].get_customfields_data 
        })
        .catch((err) => {
          this.isError = err;
          console.log(err);
        })
        .finally(() => (this.isLoading = false));

    },
     methods: {
      checkUser(){
        this.userData = this.$cookies.get('userData')
        if(!this.$cookies.get('userData')){
          this.$router.push('/login')
        }
      },

    validationForm() {
      const self = this;
      return new Promise((resolve, reject) => {
        self.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            let form = document.getElementById("feedback-form");

           
            let formData = new FormData(form);

            self.$store
              .dispatch("app-interviews/addFeedback", {
                id: this.$route.params.id,
                data: formData,
              })
              .then((res) => {
                if (res.data.success) {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Feedback Added",
                      icon: "BellIcon",
                      variant: "success",
                      text: res.data.message,
                    },
                  });
                  self.$emit("refetch-data");
                  self.$emit(
                    "update:is-add-new-interview-sidebar-active",
                    false
                  );
                } else {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Oops! Feedback Adding Failed",
                      icon: "BellIcon",
                      variant: "danger",
                      text: res.data.message,
                    },
                  });
                }
              })
              .catch((error) => {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops! Feedback Adding Failed",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error,
                  },
                });
              })
              .finally(() => (this.is_loading = false));
          } else {
            reject();
          }
        });
      });
    },

    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-interviews/getCustomFields")
        .then((res) => {
          if (res.data.success) {
            self.customFields = res.data.data;
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Custom Fields Getting Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Fields Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    },
  };
  </script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>